.CommanPadding {
    padding: 35px;
}


.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: none;
    outline: 0;
    box-shadow: none;
}

.ServicesHome {
    font-family: "Saira", sans-serif;
    text-align: center;
}

.ServicesHome h6 {
    margin-bottom: 20px;
    font-family: "Inter", sans-serif;
    line-height: 24px;
    font-size: 20px;
    color: #000;
}

.ServicesHome h3 {
    margin-bottom: 20px;
    font-size: 35px;
    color: #000;
}

.ServicesHome h3 span {
    font-weight: 600;
    font-size: 35px;
    color: #262c31;
}

.ServicesHome p {
    margin-bottom: 20px;
    font-size: 17.5px;
    color: #000;
}

.MainServiceses {
    height: 100%;
    padding: 10px;
    margin-top: 20px;
    text-align: center;
    background-color: transparent;
    font-family: "Saira", sans-serif;
    border-radius: 0px;
}

.MainServiceses i {
    font-size: 100px;
    margin-bottom: 10px;
    color: #005792;
}

.MainServiceses h2 {
    font-size: 20px;
    color: #01548d;
    margin-top: 10px;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 15px;
}

.MainServiceses p {
    font-size: 14.5px;
    color: #262c31;
    font-weight: 400;
}

.FeatureMain {
    padding: 50px;
    font-family: "Saira", sans-serif;
}

.RecentPostTop h2 {
    color: #fff;
    font-size: 50px;
    font-weight: 500;
}

.RecentPostTop h3 {
    color: #fff;
    font-size: 34px;
    font-weight: 500;
}

.RecentPostTop h4 {
    color: #fff;
    font-size: 24px;
    font-weight: 500;
}

.RecentPostTop button {
    background-color: rgb(26, 26, 26);
    font-family: "Saira", sans-serif;
    color: rgb(255, 255, 255);
    padding: 14px 20px;
    border: none;
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
}

.RecentPostTop button:hover {
    background-color: rgb(255, 255, 255);
    font-family: "Saira", sans-serif;
    color: rgb(26, 26, 26);
    padding: 14px 20px;
    font-weight: 600;
    border: none;
    font-size: 13px;
    text-transform: uppercase;
}


.AboutHome {
    font-family: "Saira", sans-serif;
    text-align: start;
    padding: 69px 69px 0px;
}

.AboutHome h6 {
    margin-bottom: 20px;
    font-family: "Inter", sans-serif;
    line-height: 24px;
    font-size: 22px;
    color: #000;
}

.AboutHome h3 {
    margin-bottom: 20px;
    font-size: 35px;
    color: #000;
}

.AboutHome h3 span {
    font-weight: 600;
    font-size: 35px;
    color: #262c31;
}

.AboutHome p {
    margin-bottom: 20px;
    font-size: 18px;
    color: #000;
}

.AboutHome h4 {
    font-size: 18.5px;
    margin-top: 40px;
    color: #000;
    font-weight: 700;
}

.AboutHome h5 {
    font-size: 28.5px;
    color: #01548d;
    font-weight: 700;
}

.AboutHomeSecond {
    font-family: "Saira", sans-serif;
    text-align: start;
    padding: 69px 69px 0px;
}

.AboutHomeSecond h6 {
    margin-bottom: 20px;
    font-family: "Inter", sans-serif;
    line-height: 24px;
    font-size: 22px;
    color: #000;
}

.AboutHomeSecond h3 {
    margin-bottom: 20px;
    font-size: 35px;
    color: #000;
}

.AboutHomeSecond h3 span {
    font-weight: 600;
    font-size: 35px;
    color: #262c31;
}

.AboutHomeSecond h4 {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    color: #000;
}

.AboutHomeSecond p {
    margin-bottom: 15px;
    font-size: 18px;
    color: #000;
}

.MainServicesesSecond i {
    font-size: 70px;
    color: #005792;
}

.CommonImg {
    width: 100%;
    height: 46.7rem;
}

.BlogCommonImg {
    padding: 0px;
    border: 1px solid rgba(229, 229, 229);
    /* cursor: pointer; */
}

.BlogCommonImg img {
    width: 100%;
    height: 12rem;
    padding: 1px;
    border: 4px solid rgba(255, 255, 255);
}

.RecentPost-Content {
    text-align: start;
    padding: 15px 0px 0px;
}

.RecentPost-Content h2 {
    color: #262c31;
    font-family: "Saira", sans-serif;
    font-weight: 500;
    line-height: 27px;
    font-size: 20px;
    margin-bottom: 15px;
    /* cursor: pointer; */
}

.RecentPost-Content p {
    color: #323131;
    font-family: "Laila", serif;
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 10px;
}


.ServicesBtn {
    padding: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}

.ServicesBtn button {
    background-color: #005792;
    color: #fff;
    padding: 13px 30px;
    font-family: "Laila", serif;
    border: none;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
}

.ServicesBtn button:hover {
    background-color: #1a1a1a;
    font-family: "Laila", serif;
    color: #fff;
    border: none;
    font-weight: 700;
    padding: 13px 30px;
    font-size: 14px;
    text-transform: uppercase;
}


.LocationsHome {
    font-family: "Saira", sans-serif;
    text-align: start;
    padding: 69px 49px 0px;
}

.LocationsHome h6 {
    margin-bottom: 20px;
    font-family: "Inter", sans-serif;
    line-height: 24px;
    font-size: 17px;
    color: #fff;
}

.LocationsHome h2 {
    margin-bottom: 16px;
    font-size: 40px;
    color: #fff;
}

.LocationsHome h3 {
    margin-bottom: 20px;
    line-height: 1.4;
    font-weight: 200;
    font-size: 20px;
    color: #bebfc4;
}

.LocationsHome p {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 200;
    color: #fff;
}

.LocationsHome p span {
    margin-left: 15px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 200;
    color: #fff;
}

.FirstButton {
    width: 40%;
    background-color: #01548d;
    border: 1px solid #01548d;
    color: #fff;
    border-radius: 2px;
    font-size: 13px;
    padding: 13px 10px;
    margin: 10px 0px;
    font-weight: 600;
    font-family: "Playfair Display", serif;
}

.FirstButton:hover {
    width: 40%;
    background-color: #1a1a1a;
    border: 1px solid #1a1a1a;
    color: #fff;
    border-radius: 2px;
    font-size: 13px;
    font-family: "Playfair Display", serif;
    padding: 13px 10px;
    margin: 10px 0px;
    font-weight: 600;
}

@media screen and (max-width: 1200px) {
    .FirstButton {
        width: 70%;
        background-color: #01548d;
        border: 1px solid #01548d;
        color: #fff;
        border-radius: 2px;
        font-size: 13px;
        padding: 13px 10px;
        margin: 10px 0px;
        font-weight: 600;
        font-family: "Playfair Display", serif;
    }
}

@media screen and (max-width: 990px) {
    .FeatureMain {
        padding: 25px;
        font-family: "Saira", sans-serif;
    }

    .AboutHome {
        font-family: "Saira", sans-serif;
        text-align: start;
        padding: 20px 20px 0px;
    }

    .AboutHome h6 {
        margin-bottom: 14px;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        font-size: 18px;
        color: #000;
    }

    .AboutHome h3 {
        margin-bottom: 14px;
        font-size: 30px;
        color: #000;
    }

    .AboutHome h3 span {
        font-weight: 600;
        font-size: 30px;
        color: #262c31;
    }

    .AboutHome p {
        margin-bottom: 14px;
        font-size: 15.5px;
        color: #000;
    }

    .AboutHome h4 {
        font-size: 14.5px;
        margin-top: 22px;
        color: #000;
        font-weight: 700;
    }

    .AboutHome h5 {
        font-size: 22.5px;
        color: #01548d;
        font-weight: 700;
    }

    .AboutHomeSecond {
        font-family: "Saira", sans-serif;
        text-align: start;
        padding: 15px 20px 0px;
    }

    .AboutHomeSecond h6 {
        margin-bottom: 15px;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        font-size: 18px;
        color: #000;
    }

    .AboutHomeSecond h3 {
        margin-bottom: 14px;
        font-size: 30px;
        color: #000;
    }

    .AboutHomeSecond h3 span {
        font-weight: 600;
        font-size: 30px;
        color: #262c31;
    }

    .AboutHomeSecond h4 {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 25px;
        font-weight: 500;
        color: #000;
    }

    .AboutHomeSecond p {
        margin-bottom: 10px;
        font-size: 13px;
        color: #000;
    }

    .MainServicesesSecond i {
        font-size: 50px;
        color: #005792;
    }


    .LocationsHome {
        font-family: "Saira", sans-serif;
        text-align: start;
        padding: 30px 20px 0px;
    }

    .RecentPostTop h2 {
        color: #fff;
        font-size: 40px;
        font-weight: 500;
    }

    .RecentPostTop h3 {
        color: #fff;
        font-size: 30px;
        font-weight: 500;
    }

    .RecentPostTop h4 {
        color: #fff;
        font-size: 20px;
        padding-top: 10px;
        font-weight: 500;
    }

    .FirstButton {
        width: 50%;
        background-color: #01548d;
        border: 1px solid #01548d;
        color: #fff;
        border-radius: 2px;
        font-size: 13px;
        padding: 13px 10px;
        margin: 10px 0px;
        font-weight: 600;
        font-family: "Playfair Display", serif;
    }
}

@media screen and (max-width: 400px) {

    .CommanPadding {
        padding: 12px;
        padding-top: 10px;
        margin-bottom: 10px;
    }

    .FeatureMain {
        padding: 10px;
        font-family: "Saira", sans-serif;
    }

    .AboutHome {
        font-family: "Saira", sans-serif;
        text-align: start;
        padding: 20px 20px 0px;
    }

    .AboutHome h6 {
        margin-bottom: 14px;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        font-size: 18px;
        color: #000;
    }

    .AboutHome h3 {
        margin-bottom: 14px;
        font-size: 30px;
        color: #000;
    }

    .AboutHome h3 span {
        font-weight: 600;
        font-size: 30px;
        color: #262c31;
    }

    .AboutHome p {
        margin-bottom: 14px;
        font-size: 15.5px;
        color: #000;
    }

    .AboutHome h4 {
        font-size: 14.5px;
        margin-top: 22px;
        color: #000;
        font-weight: 700;
    }

    .AboutHome h5 {
        font-size: 22.5px;
        color: #01548d;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .AboutHomeSecond {
        font-family: "Saira", sans-serif;
        text-align: start;
        padding: 15px 20px 0px;
    }

    .AboutHomeSecond h6 {
        margin-bottom: 15px;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        font-size: 18px;
        color: #000;
    }

    .AboutHomeSecond h3 {
        margin-bottom: 14px;
        font-size: 30px;
        color: #000;
    }

    .AboutHomeSecond h3 span {
        font-weight: 600;
        font-size: 30px;
        color: #262c31;
    }

    .AboutHomeSecond h4 {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 25px;
        font-weight: 500;
        color: #000;
    }

    .AboutHomeSecond p {
        margin-bottom: 10px;
        font-size: 13px;
        color: #000;
    }

    .MainServicesesSecond i {
        font-size: 50px;
        color: #005792;
    }

    .CommonImg {
        width: 100%;
        height: 100%;
    }

    .ServicesBtn {
        padding: 30px;
        margin-top: 0px;
        margin-bottom: 0px;
        text-align: center;
    }

    .LocationsHome {
        font-family: "Saira", sans-serif;
        text-align: start;
        padding: 30px 0px 0px;
    }

    .ServicesHome h3 {
        margin-bottom: 15px;
        margin-top: 10px;
        font-size: 26px;
        color: #000;
    }
    
    .ServicesHome h3 span {
        font-weight: 600;
        margin-bottom: 15px;
        font-size: 26px;
        color: #262c31;
    }
}