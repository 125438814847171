

.ProductBox {
    background-color: #fff;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
    padding: 1rem;
    border-radius: 2px;
    border-left: 4px solid #fff;
    font-family: "Merriweather", serif;
}


.ProductText h2{
    color: #111;
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 600;
    text-align: center;
}


.ProductText h3{
    color: #212121;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
}


.ReadMore button {
    background-color: #fff;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
    border: 2px solid black;
    color: #151515;
    margin-top: 10px;
    font-size: 14px;
    padding: 6px 20px;
    margin-bottom: 25px;
}

.ReadMore button:hover {
    background-color: black;
    color: #fff;
    border: 2px solid black;
    margin-top: 10px;
    font-size: 14px;
    padding: 6px 20px;
    margin-bottom: 25px;
}