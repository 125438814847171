 .LogoFinalSize {
     width: 100%;
     height: 80px;
 }


 .laila-semibold {
     font-family: "Laila", serif;
     font-weight: 600;
     font-style: normal;
 }

 .lato-black {
     font-family: "Lato", sans-serif;
     font-weight: 900;
     font-style: normal;
 }

 .merriweather-bold {
     font-family: "Merriweather", serif;
     font-weight: 700;
     font-style: normal;
 }


 .navbar-nav {
     display: flex;
     width: 100%;
     padding: 0px 25px 0px 0px;
     text-transform: uppercase;
     font-family: "Laila", serif;
     font-weight: 800;
 }

 .navbar-nav .nav-link {
     color: #fff;
     padding: 35px;
     font-weight: 700;
     font-size: .7211rem;
 }


 .dropdown-item {
     color: #fff;
     padding: 10px;
     font-size: 11px;
     font-weight: 500;
 }



 .dropdown-item:hover {
     background-color: #202734;
     color: #11b6e4;
 }

 .dropdown-item.active {
     background-color: transparent;
 }


 .dropdown .dropdown-menu {
     display: none;
 }

 .dropdown:hover>.dropdown-menu,
 .dropend:hover>.dropdown-menu {
     display: block;
     padding: 0px;
     font-size: .5rem;
     background-color: #202734;
     border-radius: 0px;
 }

 @media screen and (max-width: 1200px) {
     .dropend:hover>.dropdown-menu {
         position: absolute;
         top: 0;
         left: 100%;
     }


     .dropend .dropdown-toggle {
         margin-left: 0.5em;
     }


     .navbar-nav .nav-link {
         color: #fff;
         padding: 35px;
         font-size: .6411rem;
     }


     .navbar-nav {
         display: flex;
         width: 100%;
         padding: 0px 50px 0px 0px;
         text-transform: uppercase;
         font-family: "Laila", serif;
         font-weight: 800;
     }


     .LogoFinalSize {
         width: 100%;
         height: 80px;
     }
 }

 @media screen and (min-width: 1200px) {
     .dropend:hover>.dropdown-menu {
         position: absolute;
         top: 0;
         left: 100%;
     }

    
 }


 @media screen and (max-width: 1070px) {
     .dropend:hover>.dropdown-menu {
         position: absolute;
         top: 0;
         left: 100%;
     }

    

     .navbar-nav {
         display: flex;
         width: 100%;
         padding: 0px 71px 0px 0px;
         text-transform: uppercase;
         font-family: "Laila", serif;
         font-weight: 800;
     }

 }

 @media screen and (max-width: 990px) {
     .LogoFinalSize {
         width: 100%;
         height: 40px;
     }

     .navbar-nav .nav-link {
         color: #fff;
         padding: 0px;
         margin-top: 10px;
         margin-bottom: 10px;
         font-size: .6411rem;
     }
 }

 @media screen and (max-width: 400px) {
     .LogoFinalSize {
         width: 100%;
         height: 70px;
     }

     .navbar-nav .nav-link {
         color: #fff;
         padding: 0px;
         margin-top: 10px;
         margin-bottom: 10px;
         font-size: .6411rem;
     }

     .dropdown-item {
         color: #fff;
         padding: 12px 5px;
         font-weight: 500;
     }
 }