.ITServicesCommon {
    padding: 90px;
}

.FloatImg {
    float: right;
    width: 200px;
    height: 200px;
    margin: 20px 0px 20px 20px;
}

.ITServicesCommonButton {
    width: 20%;
    background-color: #005792;
    border: 1px solid #005792;
    color: #fff;
    border-radius: 2px;
    font-size: 13px;
    padding: 8px 0px;
    margin: 10px 0px;
    font-weight: 600;
    font-family: "Playfair Display", serif;
}

.ManagedItServices {
    font-family: "Saira", sans-serif;
    margin-top: 40px;
    margin-bottom: 0px;
}

.ManagedItServices h2 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 20px;
}

.ManagedItServices h3 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 5px;
}

.ManagedItServices p {
    font-size: 16px;
    color: #252525;
    text-align: justify;
    font-weight: 400;
    margin-bottom: 10px;
}

.ManagedITServicesMainServiceses {
    padding: 25px 30px;
    margin-left: 20px;
    background: linear-gradient(180deg, rgb(250, 250, 250) 0%, rgb(218, 218, 218) 50%);
    border: 1px solid #ccc;
    margin-top: 40px;
    text-align: start;
    background-color: transparent;
    font-family: "Saira", sans-serif;
    border-radius: 5px;
}

.ManagedITServicesMainServiceses h2 {
    font-size: 20px;
    color: #000;
    margin-top: 10px;
    padding-bottom: 20px;
    border-bottom: 2px solid #005792;
    font-weight: 600;
    margin-bottom: 20px;
}

.ManagedITServicesMainServiceses h6 {
    font-size: 14.5px;
    color: #252525;
    line-height: 24px;
    font-weight: 400;
}

.ManagedITServicesMainServiceses p {
    font-size: 14.5px;
    color: #252525;
    cursor: pointer;
    font-weight: 400;
}

.ManagedITServicesMainServiceses p:hover {
    font-size: 14.5px;
    color: #005792;
    font-weight: 400;
}

.CommonNotUsing{
    display: block;
    color: #fff;
}

@media screen and (max-width: 1200px) {

    .ManagedItServices {
        font-family: "Saira", sans-serif;
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .ManagedItServices p {
        font-size: 16px;
        color: #252525;
        text-align: start;
        font-weight: 400;
        margin-bottom: 6px;
    }

    .ManagedITServicesMainServiceses {
        padding: 25px 30px;
        margin-left: 0px;
        background: linear-gradient(180deg, rgb(250, 250, 250) 0%, rgb(218, 218, 218) 50%);
        border: 1px solid #ccc;
        margin-top: 30px;
        text-align: start;
        background-color: transparent;
        font-family: "Saira", sans-serif;
        border-radius: 5px;
    }

    .ITServicesCommon {
        padding: 35px;
    }

    .ITServicesCommonButton {
        width: 40%;
        background-color: #005792;
        border: 1px solid #005792;
        color: #fff;
        border-radius: 2px;
        font-size: 13px;
        padding: 8px 0px;
        margin: 10px 0px;
        font-weight: 600;
        font-family: "Playfair Display", serif;
    }
}

@media screen and (max-width: 990px) {
    .CommonNotUsing{
        display: none;
        color: #fff;
    }
}

@media screen and (max-width: 580px) {
    .FloatImg {
        float: none;
        width: 100%;
        height: 100%;
        margin: 0px 0px 20px 0px;
    }

    .ITServicesCommon {
        padding: 5px;
    }

    .ManagedItServices {
        font-family: "Saira", sans-serif;
        text-align: start;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .ManagedItServices h2 {
        font-size: 1.7rem;
        font-weight: 600;
        margin-bottom: 15px;
    }

    .ManagedITServicesMainServiceses {
        padding: 25px 30px;
        margin-left: 0px;
        background: linear-gradient(180deg, rgb(250, 250, 250) 0%, rgb(218, 218, 218) 50%);
        border: 1px solid #ccc;
        margin-top: 20px;
        text-align: start;
        background-color: transparent;
        font-family: "Saira", sans-serif;
        border-radius: 5px;
    }
}