.MainMargin {
    margin-top: -90px;
}

.orbitron {
    font-family: "Orbitron", sans-serif;
    font-style: normal;
}

.inter {
    font-family: "Inter", sans-serif;
    font-style: normal;
}

.Saira{
    font-family: "Saira", sans-serif;
}


@media screen and (max-width: 990px) {
    .MainMargin {
        margin-top: 0px;
    }
}