.IndustriesMain {
    padding: 30px;
    font-family: "Saira", sans-serif;
}

.CommanIndustriesStories{
    padding: 45px;
}

.CommanIndustries{
    color: #034ea2;
    font-family: "Merriweather", serif;
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 30px;
}


.IndustriesMain h2 {
    color: #034ea2;
    font-family: "Merriweather", serif;
    font-weight: 600;
    font-size: 40px;
}

.IndustriesMain h5 {
    color: #333;
    font-weight: 600;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.IndustriesMain p {
    font-size: 16px;
    margin-bottom: 10px;
}

.image {
    width: 100%;
    position: relative;
}

.image:hover {
    width: 100%;
    position: relative;
}

.image img {
    width: 100%;
    height: auto;
    display: block;
}

.HoverEffect:hover img {
    opacity: 0.5;
    transition: 9s;
    cursor: pointer;
}

.HoverEffect {
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
}

.content {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    padding-left: 30px;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: 0.6s;
}

.content:hover {
    opacity: 1;
    cursor: pointer;
}

.content h2 {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1.5;
    font-family: "Merriweather", serif;
    text-transform: uppercase;
    overflow: hidden;
    color: #fff;
    font-style: normal;
}

.content p {
    max-width: 300px;
    font-family: "Saira", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-style: normal;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #fff;
}

.content>* {
    transform: translateY(25px);
    transition: 0.6s;
}

.content:hover>* {
    transform: translateY(0px);
}



@media screen and (max-width: 767px) {
    .IndustriesMain {
        padding: 0px;
        font-family: "Saira", sans-serif;
    }

    .CommanIndustriesStories{
        padding: 5px;
        margin-bottom: 25px;
    }

    .CommanIndustries{
        color: #034ea2;
        font-family: "Merriweather", serif;
        font-weight: 600;
        font-size: 35px;
        margin-top: 20px;
        margin-bottom: 30px;
    }

    .content {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        padding-left: 20px;
        align-items: start;
        justify-content: center;
        flex-direction: column;
        opacity: 0;
        transition: 0.6s;
    }

    .content h2 {
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 1.5;
        font-family: "Merriweather", serif;
        text-transform: uppercase;
        overflow: hidden;
        color: #fff;
        font-style: normal;
    }
    
    .content p {
        max-width: 300px;
        font-family: "Saira", sans-serif;
        font-style: normal;
        font-weight: 300;
        font-style: normal;
        font-size: 14px;
        line-height: 1.3;
        margin-bottom: 20px;
        color: #fff;
    }
}