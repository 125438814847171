.FooterMainSize {
    padding: 90px 0px 10px 0px;
}

.FooterCommonHeading h2 {
    color: #fff;
    font-size: 24px;
    margin-bottom: 35px;
    font-weight: 500;
}

.FooterCommonHeading h6 {
    color: #fff;
    font-size: 16px;
    margin-bottom: 15px;
}

.FooterCommonHeading p {
    color: #fff;
    font-size: 13px;
    margin-bottom: 15px;
}

.FooterLoginButton {
    width: 40%;
    background-color: #005792;
    border: 1px solid #005792;
    color: #fff;
    border-radius: 2px;
    font-size: 13px;
    padding: 10px 10px;
    margin: 10px 0px;
    font-weight: 600;
    font-family: "Playfair Display", serif;
}

.FooterReadMoreButton {
    width: 38%;
    background-color: #005792;
    border: 1px solid #005792;
    color: #fff;
    border-radius: 2px;
    font-size: 13px;
    padding: 8px 0px;
    margin: 10px 0px;
    font-weight: 600;
    font-family: "Playfair Display", serif;
}

.SocialIconFooter {
    margin-top: 20px;
    margin-bottom: 10px;
}

.SocialIconFooter i {
    background-color: transparent;
    width: 38px;
    height: 38px;
    border: 2px solid #005792;
    font-size: 14px;
    color: #005792;
    margin-left: 10px;
    padding: 11.2px;
    border-radius: 25px;
}

.BottomTop {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

@media screen and (max-width: 990px) {
    .FooterReadMoreButton {
        width: 50%;
        background-color: #005792;
        border: 1px solid #005792;
        color: #fff;
        border-radius: 2px;
        font-size: 13px;
        padding: 8px 0px;
        margin: 10px 0px;
        font-weight: 600;
        font-family: "Playfair Display", serif;
    }
}

@media screen and (max-width: 400px) {
    .FooterMainSize {
        padding: 35px 10px 10px 10px;
    }


    .FooterReadMoreButton {
        width: 50%;
        background-color: #005792;
        border: 1px solid #005792;
        color: #fff;
        border-radius: 2px;
        font-size: 13px;
        padding: 8px 0px;
        margin: 10px 0px;
        font-weight: 600;
        font-family: "Playfair Display", serif;
    }
}