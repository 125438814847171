
.ServicesMainServiceses {
    height: 100%;
    padding: 30px 22px 5px;
    background-color: #ffffff;
    border: 1px solid #e7e7e7;
    margin-top: 20px;
    text-align: center;
    background-color: transparent;
    font-family: "Saira", sans-serif;
    border-radius: 8px;
}

.ServicesMainServiceses i {
    font-size: 90px;
    margin-bottom: 10px;
    cursor: pointer;
    color: #005792;
}

.ServicesMainServiceses h2 {
    font-size: 20px;
    color: #000;
    margin-top: 10px;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 16px;
}

.ServicesMainServiceses p {
    border-top: 1px solid #e7e7e7;
    padding-top: 16px;
    font-size: 14.5px;
    color: #262c31;
    font-weight: 400;
}