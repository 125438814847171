.AboutOurDiffButton {
    width: 30%;
    background-color: #005792;
    border: 1px solid #005792;
    color: #fff;
    border-radius: 2px;
    font-size: 13px;
    padding: 10px 0px;
    margin-top: 15px;
    font-weight: 600;
    font-family: "Playfair Display", serif;
}

.AboutServices {
    font-family: "Saira", sans-serif;
    padding: 10px;
}

.AboutServicesImg {
    padding: 10px;
}

.AboutServices h2 {
    font-size: 1.9rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #262c31;
}

.AboutServices p {
    font-size: 14.7px;
    font-weight: 400;
    margin-bottom: 15px;
    color: #252525;
}

.AboutServices h6 {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.4;
    color: #252525;
}

.ServicesPlanAbout {
    font-family: "Saira", sans-serif;
    text-align: center;
    padding-top: 30px;
}

.ServicesPlanAbout h3 {
    margin-bottom: 20px;
    font-size: 45px;
    color: #fff;
}

.ServicesPlanAbout p {
    width: 75%;
    margin-bottom: 20px;
    font-size: 17.5px;
    color: #fff;
}

.MainServicesesAbout {
    height: 100%;
    padding: 10px;
    margin-top: 20px;
    text-align: center;
    font-family: "Saira", sans-serif;
}

.MainServicesesAbout i {
    font-size: 40px;
    color: #fff;
}

.MainServicesesAbout h2 {
    font-size: 28px;
    color: #fff;
    margin-top: 12px;
    font-weight: 600;
    /* cursor: pointer; */
    margin-bottom: 12px;
}

.MainServicesesAbout p {
    font-size: 17.5px;
    color: #fff;
    font-weight: 400;
}

.RecentPost-Content-About {
    text-align: center;
    padding: 24px 0px 0px;
    height: 100%;
}

.RecentPost-Content-About h2 {
    color: #01548d;
    font-family: "Saira", sans-serif;
    font-weight: 600;
    line-height: 20px;
    font-size: 25px;
}

.RecentPost-Content-About p {
    color: #252525;
    font-family: "Saira", sans-serif;
    font-weight: 400;
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 10px;
}

@media screen and (max-width: 990px) {
    .AboutOurDiffButton {
        width: 40%;
        background-color: #005792;
        border: 1px solid #005792;
        color: #fff;
        border-radius: 2px;
        font-size: 13px;
        padding: 10px 0px;
        margin-top: 15px;
        font-weight: 600;
        font-family: "Playfair Display", serif;
    }

    .ServicesPlanAbout p {
        width: 100%;
        margin-bottom: 20px;
        font-size: 17.5px;
        color: #fff;
    }

    .MainServicesesAbout h2 {
        font-size: 23px;
        color: #fff;
        margin-top: 10px;
        font-weight: 600;
        cursor: pointer;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 400px) {
    .AboutOurDiffButton {
        width: 100%;
        background-color: #005792;
        border: 1px solid #005792;
        color: #fff;
        border-radius: 2px;
        font-size: 13px;
        padding: 10px 0px;
        margin-top: 15px;
        font-weight: 600;
        font-family: "Playfair Display", serif;
    }

    .AboutServices {
        font-family: "Saira", sans-serif;
        padding: 0px;
    }

    .AboutServicesImg {
        padding: 0px;
    }

    .MainServicesesAbout {
        height: 100%;
        padding: 10px;
        margin-top: 0px;
        text-align: center;
        font-family: "Saira", sans-serif;
    }
    
    .MainServicesesAbout p {
        font-size: 16.5px;
        color: #fff;
        font-weight: 400;
    }
}